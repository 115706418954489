import { memo, ReactElement, useState } from 'react'
import { createPortal } from 'react-dom'
import { useStullerEventListener, StullerEvents } from '@web/shared-util-stuller-events'
import { useMutationObserver } from '@web/shared-util-hooks'
import { components } from './components'

/**
 * An individual React mount via `createPortal`
 */
const ReactMountPortal = memo(({ target, name, props }: StullerEvents['react-mount']): ReactElement => {
  const Component = components[name]

  return createPortal(<Component {...props ?? {}} />, target)
})

/**
 * Listens for Stuller events to mount React components to elements
 */
function ReactMount (): ReactElement {
  const [portals, setPortals] = useState<Array<StullerEvents['react-mount']>>([])

  // Watch for changes to the DOM to remove portals when the elements are removed
  useMutationObserver(() => {
    const removeTargets: HTMLElement[] = []
    for (const portal of portals) {
      if (!document.body.contains(portal.target)) {
        removeTargets.push(portal.target)
      }
    }

    if (removeTargets.length > 0) {
      setPortals(ps => ps.filter(p => !removeTargets.some(t => t === p.target)))
    }
  })

  // Wait for events to add new mounts
  useStullerEventListener('react-mount', event => {
    const { target, name } = event.detail ?? {}

    if (event.detail == null || target == null || !document.body.contains(target)) {
      console.error('react-mount target not found for', name)
      return
    }

    if (name == null || components[name] == null) {
      console.error('react-mount component name not found')
      return
    }

    // If info sent looks good, add/replace new React mount
    setPortals(ps => {
      const newP = [...ps]

      if (event.detail != null) {
        const index = ps.findIndex(p => p.target === target)

        if (index > -1) {
          newP[index] = event.detail
        } else {
          newP.push(event.detail)
        }
      }

      return newP
    })
  })

  return (
    <>
      {portals.map((p, i) => <ReactMountPortal key={i} {...p} />)}
    </>
  )
}

export {
  ReactMount
}
