import { ReactElement, ReactNode } from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { client, ApolloProvider } from '@web/shared-data-access-apollo'
import { ReactMount } from './ReactMount'

interface AppProps {
  /**
   * Children of element
   */
  children: ReactNode
}

/**
 * React entry for components
 */
function App ({ children }: AppProps): ReactElement {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        {children}
      </ApolloProvider>
    </BrowserRouter>
  )
}

// Start the react components app
const node = document.querySelector('#react-mount-react-container')

render(<App><ReactMount /></App>, node)
