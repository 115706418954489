import { LoadingIndicator } from '@web/shared-ui-components'
import { lazy, ReactElement, Suspense } from 'react'
const TestMount = lazy(async () => await import('./TestMount').then(c => ({ default: c.TestMount })))
const PayBill = lazy(async () => await import('@web/receivables-pay-bill').then(c => ({ default: c.PayBill })))
const DefaultPaymentMethod = lazy(async () => await import('@web/payment-methods').then(c => ({ default: c.DefaultPaymentMethod })))
const AddAHead = lazy(async () => await import('@web/products').then(c => ({ default: c.AddAHead })))
const GetAQuote = lazy(async () => await import('@web/quote').then(c => ({ default: c.GetAQuote })))
const PaymentMethodSelector = lazy(async () => await import('@web/payment-methods').then(c => ({ default: c.PaymentMethodSelector })))
const PayBillConfirmation = lazy(async () => await import('@web/receivables-pay-bill').then(c => ({ default: c.PayBillConfirmation })))
const AddProductToProductFeedModal = lazy(async () => await import('@web/products').then(c => ({ default: c.AddProductToProductFeedModal })))
const ModifyAStyle = lazy(async () => await import('@web/cadcam-modify-a-style').then(c => ({ default: c.ModifyAStyle })))
const SubmitASketch = lazy(async () => await import('@web/cadcam-submit-a-sketch').then(c => ({ default: c.SubmitASketch })))
const SubmitACadDesign = lazy(async () => await import('@web/cadcam-submit-a-cad-design').then(c => ({ default: c.SubmitACadDesign })))
const SubmitAWaxOrResinModel = lazy(async () => await import('libs/cadcam/feature/submit-a-wax-or-resin-model').then(c => ({ default: c.SubmitAWaxOrResinModel })))
const Resubmit = lazy(async () => await import('@web/cadcam-resubmit').then(c => ({ default: c.Resubmit })))
const ResubmitWithOracleAlert = lazy(async () => await import('@web/cadcam-resubmit').then(c => ({ default: c.ResubmitWithOracleAlert })))
const StullerPayPdpCallout = lazy(async () => (await import('@web/payment-methods').then(c => ({ default: c.StullerPayPdpCallout }))))
const StullerPayFinancingTerms = lazy(async () => (await import('@web/payment-methods').then(c => ({ default: c.StullerPayFinancingTerms }))))
const StullerPayCheckoutIneligibleModal = lazy(async () => (await import('@web/payment-methods').then(c => ({ default: c.StullerPayCheckoutIneligibleModal }))))
const StullerPayCheckoutOracleDownModal = lazy(async () => (await import('@web/payment-methods').then(c => ({ default: c.StullerPayCheckoutOracleDownModal }))))
const ShipDateStatus = lazy(async () => (await import('@web/shipments').then(c => ({ default: c.ShipDateStatus }))))
const ShipCompleteOptionSelector = lazy(async () => (await import('@web/shipment-methods').then(c => ({ default: c.ShipCompleteOptionSelector }))))
const StullerPayDashboard = lazy(async () => (await import('libs/receivables/feature/stullerpay-dashboard').then(c => ({ default: c.StullerPayDashboard }))))
const PdpToolbar = lazy(async () => (await import('@web/pdp').then(c => ({ default: c.PdpToolbar }))))
const PdpImageSection = lazy(async () => (await import('@web/pdp').then(c => ({ default: c.PdpImageSection }))))
const CadCamProjectTracker = lazy(async () => (await import('@web/cadcam-project-details').then(c => ({ default: c.CadCamProjectTracker }))))
const PdpCisBadgeContainer = lazy(async () => (await import('@web/pdp').then(c => ({ default: c.PdpCisBadgeContainer }))))
const PasswordSetLinkExpired = lazy(async () => (await import('@web/users').then(c => ({ default: c.PasswordSetLinkExpired }))))
const ShippingDocumentsModal = lazy(async () => (await import('@web/cadcam-project-details').then(c => ({ default: c.ShippingDocumentsModal }))))
const MarketplaceProfile = lazy(async () => await import('@web/marketplace-solutions-profile').then(c => ({ default: c.MarketplaceProfile })))

/**
 * HOC to add Suspense w/ loading indicator for each component
 */
function withSuspense (component): (props: any) => ReactElement {
  const Component = component

  return (props) => (
    <Suspense fallback={<LoadingIndicator loading position='inline' />}>
      <Component {...props} />
    </Suspense>
  )
}

/**
 * React components that can be used for react mount
 */
const components = {
  TestMount: withSuspense(TestMount),
  DefaultPaymentMethod: withSuspense(DefaultPaymentMethod),
  PayBill: withSuspense(PayBill),
  AddAHead: withSuspense(AddAHead),
  GetAQuote: withSuspense(GetAQuote),
  PaymentMethodSelector: withSuspense(PaymentMethodSelector),
  PayBillConfirmation: withSuspense(PayBillConfirmation),
  AddProductToProductFeedModal: withSuspense(AddProductToProductFeedModal),
  ModifyAStyle: withSuspense(ModifyAStyle),
  SubmitASketch: withSuspense(SubmitASketch),
  SubmitACadDesign: withSuspense(SubmitACadDesign),
  SubmitAWaxOrResinModel: withSuspense(SubmitAWaxOrResinModel),
  Resubmit: withSuspense(Resubmit),
  ResubmitWithOracleAlert: withSuspense(ResubmitWithOracleAlert),
  StullerPayPdpCallout: withSuspense(StullerPayPdpCallout),
  StullerPayFinancingTerms: withSuspense(StullerPayFinancingTerms),
  StullerPayCheckoutIneligibleModal: withSuspense(StullerPayCheckoutIneligibleModal),
  StullerPayCheckoutOracleDownModal: withSuspense(StullerPayCheckoutOracleDownModal),
  ShipDateStatus: withSuspense(ShipDateStatus),
  ShipCompleteOptionSelector: withSuspense(ShipCompleteOptionSelector),
  StullerPayDashboard: withSuspense(StullerPayDashboard),
  PdpToolbar: withSuspense(PdpToolbar),
  PdpImageSection: withSuspense(PdpImageSection),
  CadCamProjectTracker: withSuspense(CadCamProjectTracker),
  PdpCisBadgeContainer: withSuspense(PdpCisBadgeContainer),
  PasswordSetLinkExpired: withSuspense(PasswordSetLinkExpired),
  ShippingDocumentsModal: withSuspense(ShippingDocumentsModal),
  MarketplaceProfile: withSuspense(MarketplaceProfile)
}

export {
  components
}
